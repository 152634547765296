.home-sponsor {
  flex: 0 100%;
  display: flex;
  align-items: center;
  a {
    position: relative;
    transition: padding 0.3s;
    padding: 2.5rem 0;
    height: 200px;
    width: 80%;
    display: flex;
    justify-items: center;
    align-items: center;
    &:focus {
      outline: none;
    }
  }
  img {
    opacity: .6;
    max-height: 100%;
    display: block;
  }
  a,
  img {
    transition: all 0.3s;
    margin: 0 auto;
  }
  &:hover {
    a {
      padding: 1.5rem 0 2.5rem;
    }
    img {
      opacity: 1;
    }
  }
  @media screen and (min-width: 670px) {
    flex: 0 50%;
  }
  @media screen and (min-width: 800px) {
    flex: 0 25%;
  }
}
.home-sponsors {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
  &-list {
    display: flex;
    flex-wrap: wrap;
    right: 0;
    margin-top: 4rem;
  }
}