#svg {
  display: block;
  margin: auto;
}

#chart {
  margin-top: 20px;
  border: 1px solid #dedede;
}

.bar {
  fill: steelblue;
}

.bar:hover {
  fill: #23415a;
}

.tooltip {
  position: absolute;
  opacity: 0.8;
  z-index: 1000;
  text-align: left;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 8px;
  color: #fff;
  background-color: #000;
  font: 12px sans-serif;
  max-width: 300px;
}

#wrktchart {
  text {
    fill: black;
  }
}
