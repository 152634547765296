nav {
  position: fixed;
  z-index: 101;
  width: 100%;
  .container {
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  background-color: var(--color-accent);
  h1 {
    display: flex;
    margin: 0;
    color: var(--btn-primary-label);
    font-size: 35px;
    font-weight: 700;
    line-height: 55px;
    img {
      margin-top: 5px;
      margin-bottom: 5px;
      display: block;
      max-height: 55px;
    }
  }
  #nav-menu {
    display: none;
    position: absolute;
    background-color: var(--color-accent);
    width: 100%;
    left: 0;
    top: 65px;
    padding: 10px 0;
    box-shadow: 0px 4px 3px 1px rgba(0, 0, 0, 0.3);
    li {
      padding: 5px 0;
    }
    @media (min-width: 665px) {
      box-shadow: none;
      position: relative;
      width: auto;
      top: 0;
      align-items: center;
      display: flex;
    }
  }
  a,
  a:visited {
    text-decoration: none;
    color: var(--btn-primary-label);
  }
  .burger-icon {
    svg {
      padding-right: 5px;
      // border: 2px solid white;
      // border-radius: 3px;
    }
    font-size: 28px;
    font-weight: 800;
    align-items: center;
    display: flex;
    @media (min-width: 665px) {
      display: none;
    }
  }
}
