
section .team--image img {
  // width: 100%;
}
div.noBr img {
  border-radius: 0!important;
}

.logo-charity {
  margin-bottom: var(--space-lg);
  margin-top: var(--space-lg);
  img {
    display: block;
    max-width: 300px;
    margin: 0 auto;
  }
}

.team {
  &--image {
    img {
      max-width: 100%;
      overflow: hidden;
      border-radius: var(--radius);
      box-shadow: 8px 8px 15px -7px rgba(0, 0, 0, 0.75);
    }
  }
  &--donnate {
    display: flex;
    justify-content: center;
  }
  &--illu {
    img {
      display: block;
      margin: 0 auto;
      max-width: 300px;
    }
  }
  &--list {
    &--item {
      position: relative;
      @media (min-width: 700px) {
        display: flex;
        align-items: center;
      }
      &--img {
        margin: 0 auto 1rem;
        min-width: 200px;
        width: 30%;
        @media (min-width: 700px) {
          margin: 0 3rem 0 0;
        }
        img {
          border: 5px solid var(--color-primary);
          border-radius: 50%;
          display: block;
          width: 100%;
        }
      }
      &--content {
        text-align: center;
        h4 {
          position: relative;
          width: auto;
          display: inline-block;
          &::after {
            content: '';
            height: 0.6rem;
            width: 100%;
            background-color: rgba(251, 68, 113, 0.3);
            position: absolute;
            left: 5px;
            top: 15px;
          }
        }
        p {
          text-align: left;
        }
        background-color: var(--color-primary-transparent);
        // margin-right: var(--neg-margin);
        // padding-right: var(--neg-padding);
        @media (min-width: 700px) {
          padding: 30px 0 30px 30px;
        }
        border-radius: var(--radius);
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        .team--list--item--img {
          @media (min-width: 700px) {
            margin: 0 0 0 3rem;
          }
        }
        .team--list--item--content {
          // margin-left: var(--neg-margin);
          // padding-left: var(--neg-padding);
          @media (min-width: 700px) {
            padding: 30px 30px 30px 0;
          }
        }
      }
      &::after {
        position: absolute;
        content: '';
        width: 300px;
        height: 200px;
        // background-color: silver;
      }
    }
  }
}
