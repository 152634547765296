.hero {
  .image-cover {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h2,
  h3,
  p {
    // color: white;
    margin: 0 auto;
  }
}

.presentation {
  // height: 100vh;
  position: relative;
  background-color: var(--color-accent);
  &--description {
    margin-bottom: 15px;
    @media (min-width: 700px) {
      float: left;
      width: 50%;
      margin-right: 5%;
    }
    h2,
    h4 {
      color: var(--btn-primary-label);
    }
    &--follow {
      position: absolute;
      bottom: 30vh;
      display: none;
      align-items: center;
      h3 {
        margin: 0;
      }
      a {
        font-size: 45px;
      }
      @media (min-width: 700px) {
        display: flex;
        span {
          margin-left: 1rem;
        }
      }
    }
  }
  &--img {
    // margin-right: -70px;
    width: 100%;
    margin-bottom: 15px;
    margin-top: var(--space-lg);
    @media (min-width: 700px) {
      margin-bottom: 0;
      float: right;
      width: 45%;
    }
    overflow: hidden;
    border-radius: var(--radius);
    // box-shadow: 8px 8px 15px -7px rgba(0, 0, 0, 0.75);
    img {
      display: block;
      max-width: 50%;
      margin: 0 auto;
      @media (min-width: 700px) {
        max-width: 100%;
        max-height: 61vh;
        float: right;
      }
    }
  }
  &::after {
    display: block;
    width: 100%;
    content: '';
    height: 135px;
    @media (min-width: 700px) {
      height: calc(100vh - var(--space-xxl));
    }
    background-image: url('../images/header.svg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center -1px;
    background-size: 101%;
  }
}

.charity {
  // height: 40vh;
  // background: rgb(12,25,66);
  // background: linear-gradient(45deg, rgba(229,93,135,1) 0%, rgba(121,129,151,1) 100%);
}
