footer {
  background-color: var(--color-primary);
  .container {
    text-align: center;
    color: var(--btn-primary-label);
    a {
      color: var(--btn-primary-label);
    }
    ul {
      display: flex;
      justify-content: center;
    }
  }
}
