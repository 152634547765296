@import './_variables';
@import './_mixins';
@import './_reset';
@import './_colors';
@import './_spacing';
@import './_typography';
@import './_buttons';
@import './_layout';
@import './map';
@import './workouts';
@import './chart';
@import './team';
@import './nav';
@import './heros';
@import './footer';
@import './sponsors';
@import '../node_modules/leaflet/dist/leaflet.css';

.hide {
  display: none;
}

// Page 404
.not-found {
  height: 100vh;
  position: relative;
  footer {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}
