// font weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

// colors
$body-bg: #0c1942;
$txt-primary: #d3e3fc;

$primary: #0c1942;
$secondary: #222e52;
$ternary: #fb4471;

// breakpoints
$breakpoints: (
  'sm': 767px,
  'md': 992px,
  'lg': 1200px,
) !default;
