text {
  font-size: 12px;
  stroke: none;
  fill: black;
}
text.crossBarText {
  fill: black;
  font-weight: 900;
  width: 200px;
}
path.domain {
  fill: none;
  stroke: #aaa;
}
g.tick {
  line {
    color: transparent;
    stroke: #eee;
    stroke-opacity: 1;
  }
}
.crossBar line {
  stroke: #aaa;
  stroke-width: 1.5px;
  pointer-events: none;
  shape-rendering: crispEdges;
}
.chartOverlay {
  fill: none;
  pointer-events: all;
}
.infoBox rect {
  stroke: #ccccd1;
  pointer-events: none;
  stroke-width: 1px;
  shape-rendering: crispEdges;
  font-size: 11px;
  fill: black;
}
tspan,
text.crossBarText {
  font-size: 15px;
}
tspan {
  fill: black;
}
.infoBoxElevationValue,
.infoBoxGradeValue {
  font-weight: 600;
}
.elevationChartGrid path {
  stroke-width: 0;
}
