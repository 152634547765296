#map {
  margin: 2%;
  height: 54vh;
  width: 96%;
  @media (min-width: 700px) {
    height: auto;
    width: 56%;
  }
  border-radius: 10px;
  z-index: 10;
}

.map {
  position: relative;
  @media (min-width: 700px) {
    overflow: auto;
  }
  background-color: white;
  &--bgimg {
    margin: 5% var(--space-xs);
    @media (min-width: 700px) {
      max-width: 90%;
      margin: 5% auto;
    }
    border-radius: 10px;
    background: url('../images/path1.jpg') center center / cover no-repeat;
  }
  &--wrapper {
    padding-bottom: 0.5%;
    @media (min-width: 700px) {
      height: 90vh;
      display: flex;
      padding-bottom: 0;
    }

    background-color: rgba(138, 155, 206, 0.8);
    border-radius: 10px;
  }
  &--content {
    @media (min-width: 700px) {
      width: 40%;
    }
    bottom: 10px;
    left: 0;
    border-top-right-radius: 5px;
    // z-index: 1000;
    h1,
    h2,
    h3,
    h4,
    p,
    * {
      color: white!important;
    }
    h3 {
      margin-top: 1rem;
    }
    &--all--steps li {
      margin-bottom: 1rem;
    }
    &--previous,
    &--next,
    &--download,
    &--back--all,
    &--all--steps li span {
      text-decoration: none;
      position: relative;
      width: auto;
      display: inline-block;
      &:hover {
        cursor: pointer;
        &::after {
          top: 25px;
          left: 10px;
          opacity: 0.9;
        }
      }
      &::after {
        content: '';
        height: 5px;
        width: 100%;
        background-color: #fb4471;
        opacity: 0.6;
        position: absolute;
        left: 5px;
        top: 23px;
        transition: all 0.3s;
      }
    }
    &--back--all {
      margin-bottom: 2rem;
    }
    &--prevnext {
      display: flex;
      justify-content: space-between;
      div:hover {
        cursor: pointer;
      }
    }
    &--elevation {
      max-width: 750px;
      position: absolute;
      z-index: 100;
      width: 90%;
      bottom: 3%;
      right: 5%;
      @media (min-width: 700px) {
        width: 48%;
        bottom: 11%;
        left: 43%;
      }
      overflow: hidden;
    }
  }
}

/* SVG styles */
svg {
  position: relative;
}
.lineConnect {
  fill: none;
  stroke: $ternary;
  opacity: 0.75;
  stroke-width: 2px;
}
.waypoints {
  display: none;
}

.travelMarker {
  fill: #fb4471;
}

.trackStart {
  fill: #3ac820;
}

.trackEnd {
  fill: #ff0050;
}

.blipMarker {
  fill: #2cfff6;
}
